.footer-icon {
  color: white;
  width: 20px;
}
.bottom-icon {
  color: white;
  width: 20px;
  font-size: 1.4rem;
  cursor: pointer;
}
.phone-icon {
  font-size: 1.6rem;
}
