.blog_1 {
    transition: 0.3s all;
  }
  
  .blog_1:hover .blog_hover {
    display: block;
    animation: fadeIn 0.5s forwards;
  }
  
  .blog_hover {
    display: none;
  }
  @keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(60px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
  }