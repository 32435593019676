.About_Page{   
background-image: url(../components//images/About_Background.png);
    background-repeat: no-repeat;
    background-size: cover;
  
    padding: 8rem 0rem 15rem 0rem;
   }
   .Heading_About_Page{
       color: whitesmoke; 
   }

   .About_Page_Heading{
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 300;
    font-size: 3.75rem;
    line-height: 1.2;
    letter-spacing: -0.00833em;
    font-weight: 900;
    color: #fff;
    text-align: center;
   }

   .Second_section{
    background-image: url(../components//images/background_About.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100%;
  /* padding: 8rem 0rem 15rem 0rem; */
  padding-top: 5rem;
   }

   @media screen and (max-width : 580px) {
    .Second_section{
   background-image: none;
   }
   .Box_1{
    padding-top: 0rem !important;
}
.Box_2{
padding-top: 0rem !important;
}
.Box_3{
    padding-top: 0rem !important;
}
.Box_4{
    padding-top: 0rem !important;
}
.Box_5{
      margin-top: 0rem imo !important;
}
.image1{
    padding-top: 5rem;
}
.image5{
    padding-bottom: 3rem;
}
.image4{
    padding-bottom: 2rem;
}
   
}

   .box {
    display: flex;
    /* flex-direction: row; */
     flex-wrap: wrap;
    text-align: center;
    gap: 20px;
}

.card-text{
    font-size: 14px;
}

.card-body{
    padding: 0;
}

.box>* {
    flex: 1 1 80px;
}
   
@media (min-width:991px){
    .Box_1{
        padding-top: 19rem;
    }

.Box_2{
    padding-top:13rem;
}
.Box_3{
    padding-top: 8rem;
}
.Box_4{
    padding-top: 3rem;
}
.Box_5{
      margin-top: -2rem;
}
.Arrow_image{
    margin-top: -44rem;
}
}
.Arrow_image{
    padding-bottom: 2rem;
}
.second_background_Image{
  background-image: url(../components/images/company_7.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* padding: 3rem; */
}

.first_paragraph{
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px -2px #00000045;
    padding: 50px;
    /* margin: 20px; */
    border-radius: 1rem;
}

.Second_paragraph{
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 0px 20px -2px #00000045;
    padding: 50px;
    /* margin: 20px; */
    border-radius: 1rem;
}

@media screen and (max-width : 580px) {
    .first_paragraph{
 margin-bottom: 2rem;
    } 
   
}

.makeStyles-mainBox__wrap-12 {
    padding: 2rem 0rem;
    overflow: hidden;
    margin-top: 0rem;
    background-color: #253047;
}

@media screen and (max-width : 992px) {
    .Arrow_image{
       display: none;
    
    }
    
   }

   .image5 ,.image4,.image3,.image2,.Image1 {
    -webkit-animation: mover s infinite  alternate;
    animation: mover 2s infinite  alternate;
}

@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-20px); }
}

.Line{
    display: inline-block;
    padding: 1px 30px;
   background-image: linear-gradient(red blue);
}


/* CARD COLOR START  */

.Card_1{
   border-color: #0E8BFF;
}

.Card_2{
border-color: #FF0E4A;
}

.Card_3{
 border-color: #FF8B0E;   
}

.Card_4{
  border-color: #0ABD6F;  
}

.Card_5{
    border-color: #0A28BD;
}
/* CARD COLOR END  */

.Mission{
    color: #5333F2;
}

.Our-Mission{
    font-size: 27px;
}

.SingleLine{
    width: 70px;
    height: 3px;
    background: rgb(28,107,246);
    background: linear-gradient(79deg, rgba(28,107,246,1) 22%, rgba(146,4,241,1) 41%);
    box-shadow: #707070;  
}

.card {
    border-radius: 27px 12px 12px 12px;
    padding: 1rem;
}