.Main_Box{
    background-image: url(../components//images/About_Background.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 8rem 0rem 15rem 0rem;  
}
.Main_Box_Heading{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 3.75rem;
    line-height: 1.2;
    letter-spacing: -0.00833em;
    font-weight: 900;
    color: white;
    text-align: center ;
   
}
.About_Heading{
    background-color:#FFFFFF;
    box-shadow: 0px 0px 20px -2px #00000045;
    border-radius: 3rem;
    color: #445DD6;;
    display: inline-block;
    font-size: 16px;
    padding: 15px 32px;
    
}
.Heading_About{
   text-align: center;
   padding-top: 3rem;

}
.Pillar_span{
    color: #1C6BF6;
}
.Heading_1{
    text-align: center;
    text-decoration: solid;
  
}

.heading {
    padding-top: 5rem;
}

.elementor{
    padding-top: 4rem;
    padding-left: 3rem;
}

.customers{
    text-align: center;
    padding-top: 3rem;
}

.Cards{
    border-top-left-radius: 25px;
}
.card{
    border-radius: 40px 12px 12px 12px;
    padding: 1rem;
}
.mainDiv{
    padding-bottom: 2rem;
    position: relative;
}
.firstimagelogo{
    position: absolute;
    top: -7rem;
    left: 50%;
    transform: translate(-50%);
    z-index: 1;
}
.Pillar_Box{
    padding-bottom: 3rem;
}


@media screen and (max-width : 580px) {
    
  
    .Main_Box {
        
        padding: 2rem 0rem 0rem 0rem;
    }
    .Main_Box_Heading {
        font-weight: 500;
    }

    .rowcustom{
        display:flex ;
        gap: 7rem;
        padding-bottom: 2rem
        ;
    }
}
    .circle-point {
        /* border: 1px solid #707070; */
        padding: 15px;
        border-radius: 1rem;
        /* box-shadow: 0px 0px 10px 0px gray; */
        box-shadow: 0px 0px 20px -2px #00000045;
    }
   


.Right_Aligment{
    text-align: right;
}
ul{
    list-style-type: none;
}
.text_center{
    margin-bottom: -12px;
}
.Line_Context{
    margin-left: 103px;
    width: 70px;
    height: 3px;
    background: rgb(28,107,246);
    background: linear-gradient(79deg, rgba(28,107,246,1) 22%, rgba(146,4,241,1) 41%);
    box-shadow: #707070;
   text-align: center;
}
.background_color_card :hover{
    background-color: #F4F5FA;
    
}
.card-text-paragraph{
    padding-bottom: 1rem;
}
